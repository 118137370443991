var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-form-item",
        { attrs: { label: "展示文本" } },
        [
          _c("el-input", {
            attrs: {
              value: _vm.activeData.__slot__.default,
              placeholder: "显示在分割线上的文字",
            },
            on: { input: _vm.onDefaultValueInput },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "文本位置" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.activeData["content-position"],
                callback: function ($$v) {
                  _vm.$set(_vm.activeData, "content-position", $$v)
                },
                expression: "activeData['content-position']",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "left" } }, [
                _vm._v("左边"),
              ]),
              _c("el-radio-button", { attrs: { label: "center" } }, [
                _vm._v("中间"),
              ]),
              _c("el-radio-button", { attrs: { label: "right" } }, [
                _vm._v("右边"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }